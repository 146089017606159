<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="handelType" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="系统消息"/>
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="">
                    <el-radio-group v-model="search.type" @change="handelType">
                        <el-radio-button label="1">危急值</el-radio-button>
                        <el-radio-button label="2">问卷</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="search.name" placeholder="请输入" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData" icon="el-icon-search">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <el-table :data="table.data" border stripe size="small" v-loading="loading" width="100%" height="100%"
                  :header-cell-style="{ backgroundColor: '#FAFAFA' }">
            <el-table-column type="index" label="序号" align="center"
                             :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }"
                             width="55"/>
            <el-table-column label="消息内容" width="" show-overflow-tooltip>
                <template slot-scope="scope" >
                    <div v-if="search.type == 2">
                        <div>{{scope.row.name}}: 已催办<span style="color: red;font-size: 15px">2</span>次未填写评估问卷，请及时处理！</div>
                        <div>问卷发送时间:{{scope.row.questionnaire_time|datetime}}</div>
                    </div>
                    <div v-else>
                        <div>{{scope.row.patientName}}  {{scope.row.phoneNumber}} <el-tag type="danger" size="small">三级危机</el-tag>
                            <span>{{scope.row.itemName}} :{{scope.row.itemValue}}</span>
                        </div>
                        <div>时间:{{scope.row.reportTime|datetime}}</div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                           :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                           @current-change="handlePageChange" @size-change="handlePageSizeChange"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'main-message',
        data() {
            return {
                loading: false,
                dialogVisible: false,
                search: {
                    type:"1"
                },
                table: {
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                form: {},
            }
        },
        methods: {
            handelType(){
                if(this.search.type == "1"){
                    this.getDataw()
                }  else {
                    this.getData()
                }
            },
            handlePageChange(e) {
                this.table.page = e
                this.handelType()
            },
            handlePageSizeChange(e) {
                this.table.pageSize = e
                this.handelType()
            },
            async getData() {
                this.loading = true
                try {
                    const resp = await this.$http.get(`/system/sms?page=${this.table.page}&pageSize=${this.table.pageSize}`, {params: this.search})
                    if (resp.data.status == 'success') {
                        this.table.data = resp.data.data
                        this.table.total = resp.data.total
                    } else {
                        this.$message.error(resp.data.msg)
                    }
                } catch (error) {
                    this.$message.error(error.message)
                }
                this.loading = false
            },
            async getDataw() {
                this.dialogVisible = false
                this.loading = true
                try {
                    this.$http.get(`/hut/weijizhi?page=${this.table.page}&pageSize=${this.table.pageSize}&reportStatus=0&crisisValueLevel=3`, {params: this.search})
                        .then(resp => {
                            this.table.data = resp.data.data
                            this.table.total = resp.data.total
                            this.loading = false
                        }).catch(err => {
                        this.loading = false
                        this.$message.error(err.message)
                    })
                } catch (error) {
                    this.loading = false
                    this.$message.error(error.message)
                }
            }
        },
        mounted() {
            this.getDataw()
        }
    }
</script>
<style lang="scss" scoped>
    .page {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .header {
        padding-bottom: 10px;

        .btns {
            float: right;
        }
    }

    .search {
        padding: 10px;
        background-color: #FAFAFA;
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 5px !important;
            margin-bottom: 5px !important;
        }

        .btns {
            float: right;
        }
    }

    .main-content {
        flex: 1;
        position: relative;
    }

    .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pager {
        padding-top: 10px;
        text-align: center;
    }
</style>
